<template>
  <div>
    <CRow>
      <CCol class="client-create">
        <CCard class="">
          <CCardBody class="">
            <h1>プロフィール設定</h1>
            <div class="input-box">
              <div class=""></div>
            </div>

            <div class="input-box">
              <div class="small-input">
                <div>名前：性</div>
                <input v-model="clientUserData.lastName" class="" type="text" name="name" />
              </div>
              <div class="small-input">
                <div>名</div>
                <input v-model="clientUserData.firstName" class="" type="text" />
              </div>
            </div>
            <div class="input-box">
              <div class="small-input">
                <div>ふりがな：せい</div>
                <input v-model="clientUserData.lastNameRuby" class="" type="text" />
              </div>
              <div class="small-input">
                <div>めい</div>
                <input v-model="clientUserData.firstNameRuby" class="" type="text" />
              </div>
            </div>
            <div class="input-box">
              <div class="">
                <div>性別</div>
                <input v-model="clientUserData.sex" name="sex" id="male" type="radio" value="男性" />
                <label for="male">男性</label>
                <input v-model="clientUserData.sex" name="sex" id="female" type="radio" value="女性" />
                <label for="female">女性</label>
                <input v-model="clientUserData.sex" name="sex" id="queer" type="radio" value="どちらでもない" />
                <label for="queer">どちらでもない</label>
              </div>
            </div>
            <div class="input-box">
              <div class="">
                <div class="input-title">表示名</div>
                <input v-model="displayName" class="middle-input" type="text" />
                <div class="small text-muted">・ユーザーアプリに表示させる名前を設定します。</div>
              </div>
            </div>
            <CButton color="success" @click="updateClientUserData">設定完了</CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import firebase from "firebase";
const db = firebase.firestore();
export default {
  name: "ClientUserSetting",
  data() {
    return {
      clientUserData: {},
      displayName: "",
    };
  },
  computed: {},
  created() {
    const clientUserData = this.$store.state.clientUserData.clientUserData;
    this.clientUserData = clientUserData;
    this.displayName = this.$store.state.authUser.displayName;
  },
  methods: {
    async updateClientUserData() {
      await firebase.auth().currentUser.updateProfile({
        displayName: this.displayName,
      });
      this.$router.push("/dashboard");
      localStorage.setItem("authUserName", this.displayName);
    },
  },
};
</script>
<style scoped>
h1 {
  margin-bottom: 40px;
}
.name {
  display: flex;
}

input {
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #768192;
}
input::placeholder {
  color: #bbb;
}

input[type="radio"] {
  margin: 5px;
}
.input-title {
  margin-top: 10px;
}
label {
  margin-right: 2rem;
  margin-bottom: 0px;
}

.small-input {
  margin-right: 1rem;
}

.input-box {
  display: flex;
  margin-top: 1.5rem;
  align-items: flex-end;
}

.middle-input {
  width: 300px;
}
.course select {
  width: 300px;
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #3c4b64;
}
.error-message {
  color: red;
  height: 20px;
  font-weight: bold;
}
</style>
